export const scrollToSection = (
  ref: React.RefObject<HTMLDivElement>,
  timeout?: number
) => {
  setTimeout(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" }); // Scroll to element in Tab 2
    }
  }, timeout ?? 100);
};
