export const routes = [
  {
    title: "Operator płatności",
    href: "#payment",
    name: "payment",
    main: true,
    subMenu: false,
  },
  {
    title: "Hosting",
    href: "#hosting",
    name: "hosting",
    main: true,
    subMenu: false,
  },
  {
    title: "Księgowość",
    href: "#accounting",
    name: "accounting",
    main: true,
    subMenu: true,
  },
  {
    title: "Konta firmowe",
    href: "#account",
    name: "account",
    main: true,
    subMenu: true,
  },
  {
    title: "Rejestracja domeny",
    href: "#domain",
    name: "domain",
    main: true,
    subMenu: true,
  },
  {
    title: "Współpraca",
    href: "#cooperation",
    name: "cooperation",
    main: false,
    subMenu: true,
  },
  {
    title: "O nas",
    href: "#about",
    name: "about",
    main: false,
    subMenu: true,
  },
  {
    title: "Kontakt",
    href: "#contact",
    name: "contact",
    main: false,
    subMenu: true,
  },
];
