import { Group } from "./models/Group";
import { Provider } from "./models/Provider";
import { Information } from "./models/Information";

export const Groups: Group[] = [
  {
    GroupId: "G1",
    Name: "Podstawowe informacje",
  },
  {
    GroupId: "G2",
    Name: "Funkcjonalności",
  },
  {
    GroupId: "G3",
    Name: "Dodatkowe informacje",
  },
];

export const Info: Information[] = [
  {
    InformationId: "I1",
    Name: "Opłata weryfikacyjna",
    GroupId: "G1",
    Unregistered: false,
  },
  {
    InformationId: "I2",
    Name: "Prowizja bazowa",
    GroupId: "G1",
    Unregistered: false,
  },
  {
    InformationId: "I3",
    Name: "Prowizja BLIK",
    GroupId: "G1",
    Unregistered: false,
  },
  {
    InformationId: "I4",
    Name: "Prowizja przelew",
    GroupId: "G1",
    Unregistered: false,
  },
  // {
  //   InformationId: "I5",
  //   Name: "Prowizja karta płatnicza",
  //   GroupId: "G1",
  //   Unregistered: false,
  // },
  {
    InformationId: "I21",
    Name: "Minimalna prowizja za płatność",
    GroupId: "G1",
    Unregistered: false,
  },
  {
    InformationId: "I22",
    Name: "Opłata weryfikacyjna (działalność nier.)",
    GroupId: "G1",
    Unregistered: true,
  },
  {
    InformationId: "I18",
    Name: "Prowizja bazowa (działność nier.)",
    GroupId: "G1",
    Unregistered: true,
  },
  {
    InformationId: "I19",
    Name: "Prowizja BLIK (działność nier.)",
    GroupId: "G1",
    Unregistered: true,
  },
  {
    InformationId: "I20",
    Name: "Prowizja przelew (działność nier.)",
    GroupId: "G1",
    Unregistered: true,
  },
  {
    InformationId: "I6",
    Name: "Odzyskiwanie koszyków",
    GroupId: "G2",
    Unregistered: null,
  },
  // {
  //   InformationId: "I7",
  //   Name: "Płatność przez link",
  //   GroupId: "G2",
  //   Unregistered: null,
  // },
  {
    InformationId: "I8",
    Name: "Transakcje zagraniczne",
    GroupId: "G2",
    Unregistered: null,
  },
  // {
  //   InformationId: "I9",
  //   Name: "Metody płatności",
  //   GroupId: "G2",
  //   Unregistered: null,
  // },
  {
    InformationId: "I10",
    Name: "Działalność nierejstrowana",
    GroupId: "G3",
    Unregistered: null,
  },
  {
    InformationId: "I11",
    Name: "Kontakt telefoniczny",
    GroupId: "G3",
    Unregistered: null,
  },
  // {
  //   InformationId: "I12",
  //   Name: "Największe współpracujące marki",
  //   GroupId: "G3",
  //   Unregistered: null,
  // },
  // {
  //   InformationId: "I13",
  //   Name: "Liczba wypłat w miesiącu",
  //   GroupId: "G3",
  //   Unregistered: null,
  // },
  {
    InformationId: "I14",
    Name: "Prowizja od wypłaty",
    GroupId: "G3",
    Unregistered: null,
  },
  // {
  //   InformationId: "I15",
  //   Name: "Opłata za zwrot produktu przez klienta",
  //   GroupId: "G3",
  //   Unregistered: null,
  // },
  // {
  //   InformationId: "I16",
  //   Name: "Waluty",
  //   GroupId: "G3",
  //   Unregistered: null,
  // },
  // {
  //   InformationId: "I17",
  //   Name: "Od kiedy na rynku",
  //   GroupId: "G3",
  //   Unregistered: null,
  // },
];

export const Providers: Provider[] = [
  {
    ProviderId: "P1",
    ProviderName: "HotPay",
    link: "https://hotpay.pl/",
    logo: "../../images/partners/logo-hotpay.svg",
    VerificationFee: { InformationId: "I1", Value: "10 zł" },
    CommissionBase: { InformationId: "I2", Value: "0,00 zł" },
    CommissionPercentBlik: { InformationId: "I3", Value: "0,95%" },
    CommissionPercentTransfer: { InformationId: "I4", Value: "0,95%" },
    UnregisteredCommissionBase: { InformationId: "I18", Value: "0,30 zł" },
    UnregisteredCommissionPercentBlik: {
      InformationId: "I19",
      Value: "2,45%",
    },
    UnregisteredCommissionPercentTransfer: {
      InformationId: "I20",
      Value: "2,45%",
    },
    // CommissionPercentDebitCard: { InformationId: "I5", Value: "0,95%" },
    UnregisteredCompany: { InformationId: "I10", Value: true },
    MinimumCommissionFee: { InformationId: "I21", Value: "0,3 zł" },
    UnregisteredVerificationFee: { InformationId: "I22", Value: "10,00 zł" },
  },
  {
    ProviderId: "P2",
    ProviderName: "Przelewy24",
    link: "https://www.przelewy24.pl/",
    logo: "../../images/partners/Przelewy24_logo.svg",
    VerificationFee: { InformationId: "I1", Value: "59 zł" },
    CommissionBase: { InformationId: "I2", Value: "0,30 zł" },
    CommissionPercentBlik: { InformationId: "I3", Value: "1,29%" },
    CommissionPercentTransfer: { InformationId: "I4", Value: "1,29%" },
    UnregisteredCommissionBase: null,
    UnregisteredCommissionPercentBlik: null,
    UnregisteredCommissionPercentTransfer: null,
    // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,29%" },
    UnregisteredCompany: { InformationId: "I10", Value: false },
    MinimumCommissionFee: null,
    UnregisteredVerificationFee: null,
  },
  {
    ProviderId: "P3",
    ProviderName: "PayU",
    link: "https://poland.payu.com/en/",
    logo: "../../images/partners/PAYU_GPO-dark green[27].svg",
    VerificationFee: { InformationId: "I1", Value: "10 zł" },
    CommissionBase: { InformationId: "I2", Value: "0,0 zł" },
    CommissionPercentBlik: { InformationId: "I3", Value: "1,3%" },
    CommissionPercentTransfer: { InformationId: "I4", Value: "1,3%" },
    UnregisteredCommissionBase: null,
    UnregisteredCommissionPercentBlik: null,
    UnregisteredCommissionPercentTransfer: null,
    // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,25%" },
    UnregisteredCompany: { InformationId: "I10", Value: false },
    MinimumCommissionFee: null,
    UnregisteredVerificationFee: null,
  },
  {
    ProviderId: "P4",
    ProviderName: "AutoPay",
    link: "https://autopay.pl/",
    logo: "../../images/partners/Logo-autopay.svg",
    VerificationFee: { InformationId: "I1", Value: "49 zł" },
    CommissionBase: { InformationId: "I2", Value: "0,25 zł" },
    CommissionPercentBlik: { InformationId: "I3", Value: "1,19%" },
    CommissionPercentTransfer: { InformationId: "I4", Value: "1,19%" },
    UnregisteredCommissionBase: { InformationId: "I18", Value: "0,25 zł" },
    UnregisteredCommissionPercentBlik: {
      InformationId: "I19",
      Value: "1,19%",
    },
    UnregisteredCommissionPercentTransfer: {
      InformationId: "I20",
      Value: "1,19%",
    },
    // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,19%" },
    UnregisteredCompany: { InformationId: "I10", Value: true },
    MinimumCommissionFee: null,
    UnregisteredVerificationFee: { InformationId: "I22", Value: "199,00 zł" },
  },
  {
    ProviderId: "P5",
    ProviderName: "TPay",
    link: "https://tpay.com/en",
    logo: "../../images/partners/tpay_logo_blue.svg",
    VerificationFee: { InformationId: "I1", Value: "49 zł" },
    CommissionBase: { InformationId: "I2", Value: "0,39 zł" },
    CommissionPercentBlik: { InformationId: "I3", Value: "0,99%" },
    CommissionPercentTransfer: { InformationId: "I4", Value: "0,99%" },
    UnregisteredCommissionBase: { InformationId: "I18", Value: "0,39 zł" },
    UnregisteredCommissionPercentBlik: {
      InformationId: "I19",
      Value: "1,59%",
    },
    UnregisteredCommissionPercentTransfer: {
      InformationId: "I20",
      Value: "1,59%",
    },
    // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,59%" },
    UnregisteredCompany: { InformationId: "I10", Value: true },
    MinimumCommissionFee: null,
    UnregisteredVerificationFee: { InformationId: "I22", Value: "10,00 zł" },
  },
];
