import Box from "@mui/material/Box";
import Title from "../Title/Title";
import EmailIcon from "@mui/icons-material/Email";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { scrollToSection } from "../../helpers/refHelper";
interface AccountingTabProps {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  contactRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const AccountingTab: React.FC<AccountingTabProps> = (props) => {
  return (
    <>
      <Title text="Funkcjonalność dostępna wkrótce." withLine={false} />
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <HourglassTopIcon
          sx={{
            margin: "0 auto",
            fontSize: { xs: 150, md: 200, lg: 240 }, // Responsive size
            color: "rgba(0, 0, 0, 0.15)", // Transparent black
          }}
        />
      </div>

      <div className="margin-top-40"></div>
      <div className="custom-subtitle">
        <h2 className="subtitle-centered-text">
          Jeśli świadczysz usługi w zakresie{" "}
          <span className="gradient-span">księgowości</span> i chciałbyś
          nawiązać współpracę, zapraszamy do kontaktu! Jesteśmy otwarci na
          partnerstwa, które pozwolą nam wspólnie dostarczać odpowiednią
          księgowość dla przedsiębiorców. Napisz do nas, aby omówić możliwości
          współpracy i wspólne projekty.
        </h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="54"
          height="54"
        >
          <path d="M13 2L10 12H14L11 22L17 12H13L16 2Z" fill="#05a3ff" />
        </svg>
      </div>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "center" }} // Center the button horizontally
        onClick={() => {
          props.setTab("contact");
          scrollToSection(props.contactRef);
        }}
      >
        <Box
          sx={{
            border: "1px solid #05a3ff",
            borderRadius: "16px",
            boxShadow: "0 16px 24px rgba(0, 0, 0, 0.5)",
            padding: "12px 28px", // Reduced padding
            backgroundColor: "#05a3ff",
            opacity: "80%",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0 16px 24px rgba(0, 0, 0, 0.6)",
              border: "1px solid #05a3ff",
            },
            width: "auto", // Adjust width automatically based on content
            maxWidth: "200px", // Limit the maximum width of the button
            margin: "0", // Remove extra margins
          }}
        >
          <div className="custom-subtitle">
            <Box display="flex" justifyContent="center" alignItems="center">
              <EmailIcon
                fontSize="medium"
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
              />
              <h2
                className="subtitle-centered-text"
                style={{
                  color: "#fff",
                  fontSize: 20, // Smaller font size
                  paddingLeft: 8, // Adjust padding to fit the text
                  paddingTop: 3,
                  margin: 0, // Remove extra margin
                }}
              >
                Napisz do nas
              </h2>
            </Box>
          </div>
        </Box>
      </div>
      <div className="margin-top-40"></div>
    </>
  );
};
