import SavingsModal from "../SavingsModal/SavingsModal";
import CustomTable from "../Table/CustomTable";
import SubtitleGradient from "../Subtitle/SubtitleGradient";
import { useEffect, useState } from "react";
import Subtitle from "../Subtitle/Subtitle";
import Title from "../Title/Title";
import Filter from "../Filter/Filter";
import { useProviders } from "../../hooks/useProviders";
import { calculate, ProviderData } from "../../models/ProviderData";
import Loader from "../Loader/Loader";
import card from "../../images/Karta_płatnicza.png";
import Text from "../Text/Text";
import { scrollToSection } from "../../helpers/refHelper";

const DEFAULT_NUMBER_OF_TRANSACTIONS = 100;
const DEFAULT_TRANSACTION_VALUE = 50;

interface PaymentTabProps {
  paymentRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const PaymentTab: React.FC<PaymentTabProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [numberOfTransaction, setNumberOfTransaction] = useState(
    DEFAULT_NUMBER_OF_TRANSACTIONS
  );
  const [transactionValue, setTransactionValue] = useState(
    DEFAULT_TRANSACTION_VALUE
  );

  const [unregistered, setUnregistered] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const [providers, setProviders] = useState<ProviderData[]>([]);

  const [loading, setLoading] = useState(false);

  const { data, isLoading, error } = useProviders();

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
      if (data && data?.length > 0) {
        var providerData =
          data?.filter(
            (p) =>
              (unregistered && p.AdditionalInformation.UnregistredCompany) ||
              !unregistered ||
              registered
          ) ?? [];

        const sortedProviders = [...providerData].sort(
          (a, b) =>
            calculate(
              a,
              numberOfTransaction,
              transactionValue,
              unregistered,
              registered,
              true
            ) -
            calculate(
              b,
              numberOfTransaction,
              transactionValue,
              unregistered,
              registered,
              true
            )
        );
        setProviders(sortedProviders);
      }
      setLoading(false);
    }, 2000);
    setLoading(true);
  }, [data, registered, unregistered, numberOfTransaction, transactionValue]);

  if (isLoading) return <Loader />;
  if (error instanceof Error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Title
        text="Doskonale zdajemy sobie sprawę, 
              że każdy biznes jest inny."
        withLine={true}
      />
      <Subtitle
        text="W związku z tym nie ma jednego operatora płatności, który byłby
          optymalnym rozwiązaniem dla wszystkich."
        withLine={true}
      />
      <SubtitleGradient />
      <div className="payment-check-button-box">
        <button
          onClick={() => scrollToSection(props.paymentRef)}
          className="payment-check-button"
        >
          Porównaj
        </button>
      </div>

      <div className="tableViewRef" ref={props.paymentRef}>
        <Text
          text="Przedstawiony w tabeli ranking został utworzony w oparciu o średnią wartość transakcji na rynku e-commerce w 2023 roku, która wynosiła X zł* [źródło!]."
          type="bold"
        />
      </div>

      <Text
        text="Na potrzeby obliczeń założono również liczbę transakcji na poziomie 100 miesięcznie."
        type="bold-long"
      />
      <Text
        text="Jeśli jednak specyfika Twojego biznesu jest inna to w poniższe pola możesz wprowadzić parametry swojego sklepu, aby dobrać rozwiązanie, które będzie optymalne dla Ciebie."
        type="normal-long"
      />
      <div className="filter-container">
        <Filter
          registered={registered}
          setRegistered={setRegistered}
          unregistered={unregistered}
          setUnregistered={setUnregistered}
          numberOfTransaction={numberOfTransaction}
          setNumberOfTransaction={setNumberOfTransaction}
          transactionValue={transactionValue}
          setTransactionValue={setTransactionValue}
        />
        <button
          className="check-savings-button"
          onClick={() => setIsModalOpen(true)}
        >
          Sprawdź ile zaoszczędzisz!
        </button>
      </div>
      <SavingsModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        numberOfTransaction={numberOfTransaction}
        setNumberOfTransaction={setNumberOfTransaction}
        transactionValue={transactionValue}
        setTransactionValue={setTransactionValue}
        providers={providers}
        unregistred={unregistered}
        registred={registered}
        isProvidersLoading={loading}
      />
      <CustomTable
        isLoading={loading}
        providers={providers}
        registred={registered}
        unregistred={unregistered}
      />

      <div>
        <img className="background-card" src={card} alt="Karta płatnicza" />
      </div>
    </>
  );
};
