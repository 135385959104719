import Subtitle from "../Subtitle/Subtitle";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SavingsIcon from "@mui/icons-material/Savings";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Box from "@mui/material/Box";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { scrollToSection } from "../../helpers/refHelper";

interface AboutTabProps {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  paymentRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const AboutTab: React.FC<AboutTabProps> = (props) => {
  const textName = "Sprawdź, sprzedawaj i oszczędzaj :)";
  return (
    <>
      <EmojiPeopleIcon
        sx={{
          position: "absolute",
          display: { xs: "none", md: "block" },
          fontSize: { xs: 80, md: 150 }, // Responsive size
          color: "rgba(0, 0, 0, 0.1)", // Transparent black
          top: "30%",
          right: { md: "0%", lg: "10%" },
          transform: "translate(-50%, -50%)", // Offset for proper alignment
        }}
      />
      <div className="margin-top-40"></div>
      <div className="custom-subtitle">
        <h2 className="subtitle-centered-text">
          <span className="gradient-span">Naszą misją</span>
          &nbsp;jest wspieranie przedsiębiorców w wyborze najlepszego operatora
          płatności, który odpowiada specyfice ich działalności. Rozumiemy, że
          każda firma jest inna, dlatego stworzyliśmy porównywarkę, która pomaga
          znaleźć optymalne rozwiązania w prosty i przejrzysty sposób. Dzięki
          nam, możesz podjąć świadomą decyzję i skupić się na rozwijaniu swojego
          biznesu.
        </h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="54"
          height="54"
        >
          <path d="M13 2L10 12H14L11 22L17 12H13L16 2Z" fill="#05a3ff" />
        </svg>
      </div>
      <Subtitle
        text="Który z pośredników płatności najbardziej odpowiada Twojemy biznesu?"
        withLine={true}
      />
      <div
        onClick={() => {
          props.setTab("payment");
          scrollToSection(props.paymentRef, 400);
        }}
      >
        <Box
          sx={{
            border: "1px solid #05a3ff",
            borderRadius: "16px",
            boxShadow: "0 16px 24px rgba(0, 0, 0, 0.5)",
            padding: "16px 0px",
            backgroundColor: "#05a3ff",
            opacity: "80%",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0 16px 24px rgba(0, 0, 0, 0.6)",
              border: "1px solid #05a3ff",
            },
            margin: {
              lg: "0.5% 33%",
              md: "0.5% 25%",
              sm: "0.5% 19%",
              xs: "0.5% 8%",
            },
          }}
        >
          <div className="custom-subtitle">
            <h2
              className="subtitle-centered-text"
              style={{ color: "#fff", fontSize: 22 }}
            >
              {textName}
            </h2>
          </div>
          <Box display="flex" justifyContent="center" alignItems="center">
            <EmojiObjectsIcon
              fontSize="large"
              sx={{ color: " rgba(0, 0, 0, 0.7)" }}
            />
            <ArrowRightAltIcon
              fontSize="large"
              sx={{ color: " rgba(0, 0, 0, 0.7)" }}
            />
            <CreditCardIcon
              fontSize="large"
              sx={{ color: " rgba(0, 0, 0, 0.7)" }}
            />
            <ArrowRightAltIcon
              fontSize="large"
              sx={{ color: " rgba(0, 0, 0, 0.7)" }}
            />
            <SavingsIcon
              fontSize="large"
              sx={{ color: " rgba(0, 0, 0, 0.7)" }}
            />
          </Box>
        </Box>
      </div>
      <div className="margin-top-40"></div>
    </>
  );
};
