import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import React, { useMemo } from "react";

const Backdrop = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        color: "#FFF",
        zIndex: (theme) => theme.zIndex.drawer - 1,
        background: "rgba(0, 0, 0, 0.4)",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  );
};

const Loader = ({
  backdrop = true,
  color = "inherit",
  ...props
}: { backdrop?: boolean } & CircularProgressProps) => {
  const loader = useMemo(
    () => (
      <CircularProgress
        disableShrink
        {...props}
        color={color}
        sx={{
          animationDuration: "550ms",
        }}
      />
    ),
    [props]
  );

  return backdrop ? <Backdrop>{loader}</Backdrop> : loader;
};

export default Loader;
