import { useQuery } from "@tanstack/react-query";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { ProviderData } from "../models/ProviderData";

const fetchProviders = async () => {
  const querySnapshot = await getDocs(collection(db, "providers"));
  const providers = querySnapshot.docs.map((doc) => doc.data());
  return mapToProviderData(providers);
};

export const useProviders = () => {
  return useQuery({
    queryKey: ["providers"],
    queryFn: fetchProviders,
  });
};

export const mapToProviderData = (data: any[]): ProviderData[] => {
  return data.map((item) => ({
    Functionality: {
      BasketRecovery: item.functionality.basketRecovery,
      ForeignTransactions: item.functionality.foreignTransactions,
    },
    BasicInformation: {
      UnregistredCommissionBase:
        item.basicInformation.unregistredCommissionBase,
      UnregistredCommissionPercentBlik:
        item.basicInformation.unregistredCommissionPercentBlik,
      CommissionPercentTransfer:
        item.basicInformation.commissionPercentTransfer,
      MinimumCommissionFee: item.basicInformation.minimumCommissionFee,
      MinMonthlyFee: item.basicInformation.minMonthlyFee,
      VerificationFee: item.basicInformation.verificationFee,
      ComissionPercentCard: item.basicInformation.comissionPercentCard,
      UnregistredCommissionPercentTransfer:
        item.basicInformation.unregistredCommissionPercentTransfer,
      UnregistredVerificationFee:
        item.basicInformation.unregistredVerificationFee,
      CommissionPercentBlik: item.basicInformation.commissionPercentBlik,
      CommissionBase: item.basicInformation.commissionBase,
    },
    ProviderName: item.providerName,
    Link: item.link,
    Logo: item.logo,
    AdditionalInformation: {
      ReturnFee: item.additionalInformation.returnFee,
      ComissionFromWithdrawal:
        item.additionalInformation.comissionFromWithdrawal,
      NumberOfMonthlyWithdrawal:
        item.additionalInformation.numberOfMonthlyWithdrawal,
      UnregistredCompany: item.additionalInformation.unregistredCompany,
      StartedAt: item.additionalInformation.startedAt,
      PhoneContact: item.additionalInformation.phoneContact,
    },
  }));
};
