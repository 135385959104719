import "./Title.css";

interface TitleProps {
  text: string;
  withLine?: boolean;
}

const Title: React.FC<TitleProps> = (props) => {
  return (
    <div className="custom-title">
      <h1 className="centered-text">{props.text}</h1>
      {props.withLine && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="54"
          height="54"
        >
          <path d="M13 2L10 12H14L11 22L17 12H13L16 2Z" fill="#05a3ff" />
        </svg>
      )}
    </div>
  );
};

export default Title;
