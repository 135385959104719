import "./Filter.css";
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

interface FilterPros {
  registered: boolean;
  setRegistered: React.Dispatch<React.SetStateAction<boolean>>;
  unregistered: boolean;
  setUnregistered: React.Dispatch<React.SetStateAction<boolean>>;
  numberOfTransaction: number;
  setNumberOfTransaction: React.Dispatch<React.SetStateAction<number>>;
  transactionValue: number;
  setTransactionValue: React.Dispatch<React.SetStateAction<number>>;
}

const Filter: React.FC<FilterPros> = (props) => {
  const {
    numberOfTransaction,
    setNumberOfTransaction,
    transactionValue,
    setTransactionValue,
  } = props;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "," || event.key === ".") {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="filter-container">
        <div className="filter-parameters">
          <div className="filter-checkboxes">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  className="filter-checkbox"
                  size="medium"
                  inputProps={{
                    "aria-label": "Firma",
                  }}
                  value={props.registered}
                  onChange={() => {
                    props.setRegistered(!props.registered);
                  }}
                />
              }
              label={
                <Typography
                  className="checkbox-label"
                  sx={{ fontFamily: "League Spartan", fontSize: "large" }}
                >
                  Firma
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  className="filter-checkbox"
                  size="medium"
                  inputProps={{
                    "aria-label": "Działalność nierejestrowa",
                  }}
                  value={props.unregistered}
                  onChange={() => {
                    props.setUnregistered(!props.unregistered);
                  }}
                />
              }
              label={
                <Typography
                  className="checkbox-label"
                  sx={{ fontFamily: "League Spartan", fontSize: "large" }}
                >
                  Działalność nierejestrowa
                </Typography>
              }
              labelPlacement="end"
            />
          </div>

          <div className="filter-values">
            <div className="filter-values-box">
              <Tooltip title="Miesięczna liczba transakcji">
                <ShoppingCartCheckoutIcon fontSize="medium" />
              </Tooltip>
              <TextField
                id="outlined-number"
                className="filter-values-textfield"
                label="Miesięczna liczba transakcji"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={numberOfTransaction}
                onChange={(e) =>
                  setNumberOfTransaction(parseInt(e.target.value))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
                inputProps={{ style: { textAlign: "right" } }}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="filter-values-box">
              <Tooltip title="Średnia wartość transakcji">
                <ReceiptLongIcon fontSize="medium" />
              </Tooltip>

              <TextField
                id="outlined-number"
                className="filter-values-textfield-next"
                label="Średnia wartość transakcji"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={transactionValue}
                inputProps={{ style: { textAlign: "right" } }}
                onChange={(e) => setTransactionValue(parseInt(e.target.value))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {transactionValue !== 0 ? "zł" : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
