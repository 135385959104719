import HandshakeIcon from "@mui/icons-material/Handshake";
import Box from "@mui/material/Box";
import EmailIcon from "@mui/icons-material/Email";
import { useRef, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { useAddMessage } from "../../hooks/useMessages";
import Loader from "../Loader/Loader";
import { SuccessModal } from "../SuccessModal/SuccessModal";
import { scrollToSection } from "../../helpers/refHelper";

interface ContactTabProps {
  contactRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const ContactTab: React.FC<ContactTabProps> = (props) => {
  const [formData, setFormData] = useState({ email: "", text: "" });
  const [errors, setErrors] = useState({ email: "", text: "" });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { mutateAsync: create, status, error } = useAddMessage();

  const validate = () => {
    let isValid = true;
    const newErrors = { email: "", text: "" };

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email jest wymagany.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Wprowadź poprawny e-mail.";
      isValid = false;
    }

    // Text field validation
    if (!formData.text) {
      newErrors.text = "Wiadomość nie może być pusta.";
      isValid = false;
    } else if (formData.text.length < 20) {
      newErrors.text = "Wiadomość powinna zawierać co najmniej 20 znaków.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      create({
        message: formData.text,
        email: formData.email,
        timestamp: new Date().toISOString(),
      });
      setIsModalOpen(true);
      setFormData({ email: "", text: "" });
      setErrors({ email: "", text: "" });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  if (status === "pending") return <Loader />;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack on mobile, side-by-side on larger screens
          marginBottom: { xs: 4, md: 4 },
          paddingBottom: { xs: 8, md: 6 },
          borderBottom: "1px dashed lightgray",
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 4,
            borderR: "dashed 4px #fff",
          }}
        >
          <HandshakeIcon
            sx={{
              position: "absolute",
              fontSize: { xs: 150, md: 200, lg: 240 }, // Responsive size
              color: "rgba(0, 0, 0, 0.15)", // Transparent black
              top: { xs: "60%", md: "60%", lg: "45%" },
              left: { xs: "85%", md: "50%", lg: "52%" },
              transform: "translate(-50%, -50%)", // Center the icon
            }}
          />
          <div className="custom-subtitle">
            <h2 className="subtitle-centered-text">
              Dołącz do grona naszych{" "}
              <span className="gradient-span">
                partnerów i promuj rozwiązania
              </span>
              , które ułatwiają życie przedsiębiorcom. Skontaktuj się, aby
              poznać szczegóły naszej oferty współpracy i zobaczyć, jakie
              korzyści może przynieść wspólne działanie!
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="54"
              height="54"
            >
              <path d="M13 2L10 12H14L11 22L17 12H13L16 2Z" fill="#05a3ff" />
            </svg>
          </div>

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }} // Center the button horizontally
            onClick={() => scrollToSection(tableRef)}
          >
            <Box
              sx={{
                border: "1px solid #05a3ff",
                borderRadius: "16px",
                boxShadow: "0 16px 24px rgba(0, 0, 0, 0.5)",
                padding: "12px 28px", // Reduced padding
                backgroundColor: "#05a3ff",
                opacity: "80%",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: "0 16px 24px rgba(0, 0, 0, 0.6)",
                  border: "1px solid #05a3ff",
                },
                width: "auto", // Adjust width automatically based on content
                maxWidth: "200px", // Limit the maximum width of the button
                margin: "0", // Remove extra margins
              }}
            >
              <div className="custom-subtitle">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <EmailIcon
                    fontSize="medium"
                    sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                  />
                  <h2
                    className="subtitle-centered-text"
                    style={{
                      color: "#fff",
                      fontSize: 20, // Smaller font size
                      paddingLeft: 8, // Adjust padding to fit the text
                      paddingTop: 3,
                      margin: 0, // Remove extra margin
                    }}
                  >
                    Kontakt
                  </h2>
                </Box>
              </div>
            </Box>
          </div>
        </Box>

        {/* Right Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: 4,
            paddingTop: { xs: 4, md: 40 },
          }}
        >
          <div className="custom-subtitle">
            <h2 className="subtitle-centered-text">
              Chcesz dotrzeć do większej liczby przedsiębiorców? Umieść
              swoje&nbsp;
              <span className="gradient-span">
                usługi w naszej porównywarce
              </span>
              operatorów płatności. Skontaktuj się z nami, aby omówić warunki
              współpracy.
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="54"
              height="54"
            >
              <path d="M13 2L10 12H14L11 22L17 12H13L16 2Z" fill="#05a3ff" />
            </svg>
          </div>

          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }} // Center the button horizontally
            onClick={() => scrollToSection(tableRef)}
          >
            <Box
              sx={{
                border: "1px solid #05a3ff",
                borderRadius: "16px",
                boxShadow: "0 16px 24px rgba(0, 0, 0, 0.5)",
                padding: "12px 28px", // Reduced padding
                backgroundColor: "#05a3ff",
                opacity: "80%",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: "0 16px 24px rgba(0, 0, 0, 0.6)",
                  border: "1px solid #05a3ff",
                },
                width: "auto", // Adjust width automatically based on content
                maxWidth: "200px", // Limit the maximum width of the button
                margin: "0", // Remove extra margins
              }}
            >
              <div className="custom-subtitle">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <EmailIcon
                    fontSize="medium"
                    sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                  />
                  <h2
                    className="subtitle-centered-text"
                    style={{
                      color: "#fff",
                      fontSize: 20, // Smaller font size
                      paddingLeft: 8, // Adjust padding to fit the text
                      paddingTop: 3,
                      margin: 0, // Remove extra margin
                    }}
                  >
                    Kontakt
                  </h2>
                </Box>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: 400,
          margin: "0 auto",
          padding: 4,
          borderRadius: 4,
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          marginBottom: 8,
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
          },
        }}
        ref={props.contactRef}
      >
        <Typography
          variant="h5"
          gutterBottom
          ref={tableRef}
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#333",
            marginBottom: 2,
          }}
        >
          Skontaktuj się z nami
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* Email Field */}
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            margin="normal"
            InputProps={{
              style: { color: "#495057" }, // Slightly darker grey text
            }}
            InputLabelProps={{
              style: { color: "#6c757d" }, // Muted grey label
            }}
            sx={{
              backgroundColor: "#f8f9fa", // Lighter grey for input background
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#e9ecef",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused": {
                  borderColor: "#3CD4A0",
                },
              },
            }}
          />
          {/* Text Field */}
          <TextField
            fullWidth
            label="Wiadomość"
            name="text"
            value={formData.text}
            onChange={handleChange}
            error={!!errors.text}
            helperText={errors.text}
            margin="normal"
            multiline
            rows={4}
            InputProps={{
              style: { color: "#495057" }, // Slightly darker grey text
            }}
            InputLabelProps={{
              style: { color: "#6c757d" }, // Muted grey label
            }}
            sx={{
              backgroundColor: "#f8f9fa", // Lighter grey for input background
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#e9ecef",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused": {
                  borderColor: "#3CD4A0",
                },
              },
            }}
          />
          {/* Submit Button */}
          <button
            type="submit"
            className="payment-check-button"
            style={{
              display: "block",
              margin: "20px auto 0",
              padding: "12px 24px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: "#3CD4A0",
              border: "none",
              borderRadius: "24px",
              cursor: "pointer",
              transition: "background-color 0.3s ease, transform 0.3s ease",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = "#34b48c";
              e.currentTarget.style.transform = "translateY(-2px)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = "#3CD4A0";
              e.currentTarget.style.transform = "translateY(0)";
            }}
          >
            Wyślij
          </button>
          {status === "error" && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                textAlign: "center",
                marginTop: 2,
                fontStyle: "italic",
              }}
            >
              {error.message}
            </Typography>
          )}
        </form>
      </Box>
      <SuccessModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </>
  );
};
