import { useEffect, useRef, useState } from "react";
import "./App.css";

import Header from "./components/Header/Header";

import "@fontsource/league-spartan";
import { NavMobile } from "./components/NavMobile/NavMobile";

import { signInAnonymously } from "firebase/auth";
import { auth } from "./firebase";

import { PaymentTab } from "./components/Tabs/PaymentTab";
import { AboutTab } from "./components/Tabs/AboutTab";
import { CooperateTab } from "./components/Tabs/CooperateTab";
import { ContactTab } from "./components/Tabs/ContactTab";
import { AccountingTab } from "./components/Tabs/AccountingTab";
import { HostingTab } from "./components/Tabs/HostingTab";
import { DomainTab } from "./components/Tabs/DomainTab";
import { AccountTab } from "./components/Tabs/AccountTab";

function App() {
  const [selectedTab, setSelectedTab] = useState<string>("payment");

  const contactRef = useRef<HTMLDivElement | null>(null);
  const paymentRef = useRef<HTMLDivElement | null>(null);

  const signInAnonymouslyUser = async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      return userCredential.user;
    } catch (error) {
      console.error("Error signing in anonymously:", error);
    }
  };

  useEffect(() => {
    const signIn = async () => {
      await signInAnonymouslyUser();
    };
    signIn();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <>
      <Header
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      {selectedTab === "payment" && (
        <>
          <PaymentTab paymentRef={paymentRef} />
        </>
      )}
      {selectedTab === "accounting" && (
        <>
          <AccountingTab setTab={setSelectedTab} contactRef={contactRef} />
        </>
      )}
      {selectedTab === "account" && (
        <>
          <AccountTab setTab={setSelectedTab} contactRef={contactRef} />
        </>
      )}
      {selectedTab === "cooperation" && (
        <>
          <CooperateTab setTab={setSelectedTab} contactRef={contactRef} />
        </>
      )}
      {selectedTab === "about" && (
        <>
          <AboutTab setTab={setSelectedTab} paymentRef={paymentRef} />
        </>
      )}
      {selectedTab === "contact" && (
        <>
          <ContactTab contactRef={contactRef} />
        </>
      )}
      {selectedTab === "hosting" && (
        <>
          <HostingTab setTab={setSelectedTab} contactRef={contactRef} />
        </>
      )}
      {selectedTab === "domain" && (
        <>
          <DomainTab setTab={setSelectedTab} contactRef={contactRef} />
        </>
      )}
      {isMenuOpen ? (
        <div id="mobile-menu">
          <NavMobile
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            setSelectedTab={setSelectedTab}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default App;
