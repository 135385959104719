import { Dialog, DialogContent, Typography } from "@mui/material";

interface SuccessModalProps {
  closeModal: () => void;
  isOpen: boolean;
}
export const SuccessModal = ({ isOpen, closeModal }: SuccessModalProps) => {
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.2)", // Dim background
      }}
    >
      <DialogContent
        sx={{
          textAlign: "center",
          padding: "40px",
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Gradient Circle with Checkmark */}
        <div
          style={{
            width: "100px",
            height: "100px",
            margin: "0 auto",
            marginBottom: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            background: "linear-gradient(135deg, #ddfee6, #bad2ff)", // Gradient
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 0 24 24"
            width="48"
            fill="white"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
          </svg>
        </div>

        {/* Title */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "8px",
            color: "#333",
          }}
        >
          Wysłane!
        </Typography>

        {/* Description */}
        <Typography
          variant="body1"
          sx={{
            color: "#6c757d",
            marginBottom: "24px",
            fontStyle: "italic",
          }}
        >
          Dziękujemy za wiadomość, postaramy się jak najszybciej odpowiedzieć!
        </Typography>

        {/* Button */}
        <button
          onClick={closeModal}
          style={{
            backgroundColor: "#05a3ff",
            color: "white",
            border: "none",
            borderRadius: "24px",
            padding: "12px 24px",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            letterSpacing: "0.5px",
          }}
          className="payment-check-button"
        >
          Zamknij
        </button>
      </DialogContent>
    </Dialog>
  );
};
