import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAZ1eq3mshqWENSbcuA5V8aHjaQxotXpoE",
  authDomain: "startbiznesu-632f4.firebaseapp.com",
  projectId: "startbiznesu-632f4",
  storageBucket: "startbiznesu-632f4.firebasestorage.app",
  messagingSenderId: "729471663195",
  appId: "1:729471663195:web:65d90c993fbafe667f137e",
  measurementId: "G-L1SJ66H6C2",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
