import "./Table.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { Groups, Info } from "../../constants";
import { ProviderData } from "../../models/ProviderData";

export interface CustomTableProps {
  isLoading: boolean;
  providers: ProviderData[];
  registred: boolean;
  unregistred: boolean;
}

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const { isLoading, providers } = props;

  const showRegistredFields = () => {
    return (!props.registred && !props.unregistred) || props.registred;
  };
  const showUnregistredFields = () => {
    return props.unregistred;
  };

  const getValue = (value: any) => {
    if (typeof value == "boolean") {
      if (value === true) {
        return <CheckCircleIcon color="success" />;
      }
      return <HighlightOffIcon color="error" />;
    } else if (value == null) {
      return <RemoveCircleOutlineIcon color="disabled" />;
    } else if (typeof value == "string") {
      return value;
    }
    if (isMoney(value)) {
      if (value.value === 0) return <MoneyOffIcon color="disabled" />;

      return `${value.value} ${value.currency}`;
    }

    return value;
  };

  const isMoney = (obj: any) => {
    return (
      obj && typeof obj.currency === "string" && typeof obj.value === "number"
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto",
        overflowY: "hidden",
        width: "100%",
        pt: 2,
      }}
    >
      <Table
        sx={{
          minWidth: "100%",
          borderCollapse: "collapse",
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
            }}
          >
            <TableCell
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 1100,
              }}
            >
              {/* Empty for sticky first column */}
            </TableCell>
            {providers.map((provider, index) => (
              <TableCell key={index + provider.ProviderName} align="center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      <Typography
                        variant="caption"
                        fontWeight={550}
                        fontSize={24}
                        p={2}
                      >
                        {index + 1}
                      </Typography>
                      <Link href={provider.Link} target="_blank">
                        <img
                          src={provider.Logo}
                          alt={`Provider ${index + 1}`}
                          height="24"
                        />
                      </Link>
                      <Link href={provider.Link} target="_blank">
                        <button className="payment-provider-button">
                          Wybieram
                        </button>
                      </Link>
                    </>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <React.Fragment key={Groups[0].GroupId}>
            <TableRow sx={{ zIndex: 0 }}>
              <TableCell
                colSpan={providers.length + 1}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {Groups[0].Name}
              </TableCell>
            </TableRow>
            {showRegistredFields() && (
              <TableRow key={Info[0].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[0].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[0].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      getValue(provider.BasicInformation.VerificationFee)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {showRegistredFields() && (
              <TableRow key={Info[1].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[1].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[1].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      getValue(provider.BasicInformation.CommissionBase)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {showRegistredFields() && (
              <TableRow key={Info[2].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[2].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[2].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      `${getValue(
                        provider.BasicInformation.CommissionPercentBlik
                      )}%`
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {showRegistredFields() && (
              <TableRow key={Info[3].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[3].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[3].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      `${getValue(
                        provider.BasicInformation.CommissionPercentTransfer
                      )}%`
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}

            <TableRow key={Info[4].InformationId}>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1050,
                }}
              >
                {Info[4].Name}
              </TableCell>
              {providers.map((provider) => (
                <TableCell
                  key={`${provider.ProviderName}-${Info[4].InformationId}`}
                  align="center"
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    getValue(provider.BasicInformation.MinimumCommissionFee)
                  )}
                </TableCell>
              ))}
            </TableRow>
            {showUnregistredFields() && (
              <TableRow key={Info[5].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[5].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[5].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      getValue(
                        provider.BasicInformation.UnregistredVerificationFee
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {showUnregistredFields() && (
              <TableRow key={Info[6].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[6].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[6].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      getValue(
                        provider.BasicInformation.UnregistredCommissionBase
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {showUnregistredFields() && (
              <TableRow key={Info[7].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[7].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[7].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : provider.BasicInformation
                        .UnregistredCommissionPercentTransfer !== undefined ? (
                      `${getValue(
                        provider.BasicInformation
                          .UnregistredCommissionPercentTransfer
                      )}%`
                    ) : (
                      getValue(
                        provider.BasicInformation
                          .UnregistredCommissionPercentTransfer
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
            {showUnregistredFields() && (
              <TableRow key={Info[8].InformationId}>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1050,
                  }}
                >
                  {Info[8].Name}
                </TableCell>
                {providers.map((provider) => (
                  <TableCell
                    key={`${provider.ProviderName}-${Info[8].InformationId}`}
                    align="center"
                  >
                    {isLoading ? (
                      <Skeleton />
                    ) : provider.BasicInformation
                        .UnregistredCommissionPercentTransfer !== undefined ? (
                      `${getValue(
                        provider.BasicInformation
                          .UnregistredCommissionPercentTransfer
                      )}%`
                    ) : (
                      getValue(
                        provider.BasicInformation
                          .UnregistredCommissionPercentTransfer
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </React.Fragment>
          <React.Fragment key={Groups[1].GroupId}>
            <TableRow sx={{ zIndex: 0 }}>
              <TableCell
                colSpan={providers.length + 1}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {Groups[1].Name}
              </TableCell>
            </TableRow>

            <TableRow key={Info[9].InformationId}>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1050,
                }}
              >
                {Info[9].Name}
              </TableCell>
              {providers.map((provider) => (
                <TableCell
                  key={`${provider.ProviderName}-${Info[9].InformationId}`}
                  align="center"
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    getValue(provider.Functionality.BasketRecovery)
                  )}
                </TableCell>
              ))}
            </TableRow>

            <TableRow key={Info[10].InformationId}>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1050,
                }}
              >
                {Info[10].Name}
              </TableCell>
              {providers.map((provider) => (
                <TableCell
                  key={`${provider.ProviderName}-${Info[10].InformationId}`}
                  align="center"
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    getValue(provider.Functionality.ForeignTransactions)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </React.Fragment>
          <React.Fragment key={Groups[2].GroupId}>
            <TableRow sx={{ zIndex: 0 }}>
              <TableCell
                colSpan={providers.length + 1}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {Groups[2].Name}
              </TableCell>
            </TableRow>

            <TableRow key={Info[11].InformationId}>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1050,
                }}
              >
                {Info[11].Name}
              </TableCell>
              {providers.map((provider) => (
                <TableCell
                  key={`${provider.ProviderName}-${Info[11].InformationId}`}
                  align="center"
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    getValue(provider.AdditionalInformation.UnregistredCompany)
                  )}
                </TableCell>
              ))}
            </TableRow>

            <TableRow key={Info[12].InformationId}>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1050,
                }}
              >
                {Info[12].Name}
              </TableCell>
              {providers.map((provider) => (
                <TableCell
                  key={`${provider.ProviderName}-${Info[12].InformationId}`}
                  align="center"
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    getValue(provider.AdditionalInformation.PhoneContact)
                  )}
                </TableCell>
              ))}
            </TableRow>
            <TableRow key={Info[13].InformationId}>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1050,
                }}
              >
                {Info[13].Name}
              </TableCell>
              {providers.map((provider) => (
                <TableCell
                  key={`${provider.ProviderName}-${Info[13].InformationId}`}
                  align="center"
                >
                  {isLoading ? (
                    <Skeleton />
                  ) : provider.AdditionalInformation.ComissionFromWithdrawal ===
                    0 ? (
                    <MoneyOffIcon color="disabled" />
                  ) : (
                    getValue(
                      provider.AdditionalInformation.ComissionFromWithdrawal
                    )
                  )}
                </TableCell>
              ))}
            </TableRow>
          </React.Fragment>
        </TableBody>
      </Table>
    </Box>
  );
};

export default CustomTable;
