import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { useMutation } from "@tanstack/react-query";

export interface MessagePayload {
  email: string;
  message: string;
  timestamp: string;
}

// Mutation Function
const addMessage = async (payload: MessagePayload): Promise<void> => {
  const messagesRef = collection(db, "messages");
  await addDoc(messagesRef, payload);
};

export const useAddMessage = () =>
  useMutation({
    mutationFn: (payload: MessagePayload) => addMessage(payload),
  });
