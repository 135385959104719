import "./Header.css";
import myImage from "../../images/startbiznesu.svg";
import StaticTabs from "../StaticTabs/StaticTabs";
import { Twirl as Hamburger } from "hamburger-react";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

interface HeaderProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <header className="header">
      <div className="logo" onClick={() => props.setSelectedTab("payment")}>
        <img style={{ height: 67 }} src={myImage} alt="logo" />
      </div>
      <StaticTabs
        selectedTab={props.selectedTab}
        setSelectedTab={props.setSelectedTab}
      />

      <Hamburger
        size={20}
        toggled={props.isMenuOpen}
        toggle={props.setIsMenuOpen}
      />

      {props.isMenuOpen && (
        <>
          <DropdownMenu
            setSelectedTab={props.setSelectedTab}
            setIsMenuOpen={props.setIsMenuOpen}
            isMenuOpen={props.isMenuOpen}
          />
        </>
      )}
    </header>
  );
};

export default Header;
