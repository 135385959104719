export interface ProviderData {
  Functionality: Functionality;
  BasicInformation: BasicInformation;
  ProviderName: string;
  Link: string;
  Logo: string;
  AdditionalInformation: AdditionalInformation;
}

export interface AdditionalInformation {
  ReturnFee: number;
  ComissionFromWithdrawal: number;
  NumberOfMonthlyWithdrawal: number;
  UnregistredCompany: boolean;
  StartedAt: number;
  PhoneContact: boolean;
}

export interface BasicInformation {
  UnregistredCommissionBase?: Money;
  UnregistredCommissionPercentBlik?: number;
  CommissionPercentTransfer: number;
  MinimumCommissionFee: Money;
  MinMonthlyFee: Money;
  VerificationFee: Money;
  ComissionPercentCard: number;
  UnregistredCommissionPercentTransfer?: number;
  UnregistredVerificationFee?: Money;
  CommissionPercentBlik: number;
  CommissionBase: Money;
}

export interface Functionality {
  BasketRecovery: boolean;
  ForeignTransactions: boolean;
}

export interface Money {
  currency: string;
  value: number;
}

export const averageCommissionPercentage = (
  provider: ProviderData,
  unregistered: boolean,
  registered: boolean
): number => {
  if (unregistered && !registered) {
    return (
      ((provider.BasicInformation.UnregistredCommissionPercentBlik ?? 0) +
        (provider.BasicInformation.UnregistredCommissionPercentTransfer ?? 0)) /
      2
    );
  } else {
    return (
      (provider.BasicInformation.CommissionPercentBlik +
        provider.BasicInformation.CommissionPercentTransfer) /
      (2 * 100)
    );
  }
};

export const calculate = (
  provider: ProviderData,
  numberOfTransaction: number,
  transactionValue: number,
  unregistered: boolean,
  registered: boolean,
  withVerificationFee?: boolean
): number => {
  var averageCommission = averageCommissionPercentage(
    provider,
    unregistered,
    registered
  );
  var commision = averageCommission * transactionValue;

  if (commision !== 0) {
    if (unregistered && !registered) {
      commision =
        commision +
        (provider.BasicInformation.UnregistredCommissionBase?.value ?? 0);
    } else {
      commision = commision + provider.BasicInformation.CommissionBase.value;
    }

    if (
      provider.BasicInformation.MinimumCommissionFee.value !== 0 &&
      commision < provider.BasicInformation.MinimumCommissionFee.value
    ) {
      commision = provider.BasicInformation.MinimumCommissionFee.value;
    }
  }

  return (
    (numberOfTransaction * commision * 12 +
      (withVerificationFee
        ? provider.BasicInformation.VerificationFee.value
        : 0)) /
    12
  );
};
