import "./Subtitle.css";

interface SubtitleProps {
  text: string;
  withLine?: boolean;
}

const Subtitle: React.FC<SubtitleProps> = (props) => {
  return (
    <div className="custom-subtitle">
      <h2 className="subtitle-centered-text">{props.text}</h2>
      {props.withLine && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="54"
          height="54"
        >
          <path d="M13 2L10 12H14L11 22L17 12H13L16 2Z" fill="#05a3ff" />
        </svg>
      )}
    </div>
  );
};

export default Subtitle;
