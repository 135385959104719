import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Slider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Close } from "@mui/icons-material";
import { calculate, ProviderData } from "../../models/ProviderData";
import { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

interface SavingsModalProps {
  closeModal: () => void;
  isOpen: boolean;
  numberOfTransaction: number;
  setNumberOfTransaction: React.Dispatch<React.SetStateAction<number>>;
  transactionValue: number;
  setTransactionValue: React.Dispatch<React.SetStateAction<number>>;
  providers: ProviderData[];
  registred: boolean;
  unregistred: boolean;
  isProvidersLoading: boolean;
}
const MIN_NUMBER_OF_TRANSACTION = 1;
const MAX_NUMBER_OF_TRANSACTION = 5000;

const MIN_VALUE_OF_TRANSACTION = 1;
const MAX_VALUE_OF_TRANSACTION = 2000;

const SavingsModal = ({
  isOpen,
  closeModal,
  numberOfTransaction,
  setNumberOfTransaction,
  transactionValue,
  setTransactionValue,
  providers,
  registred,
  unregistred,
  isProvidersLoading,
}: SavingsModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [savings, setSavings] = useState<number>(0);
  const [savingsWithVerificationFee, setSavingsWithVerificationFee] =
    useState<number>(0);

  useEffect(() => {
    if (providers.length > 0) {
      setSavingsWithVerificationFee(
        Math.round(
          (calculate(
            providers[providers.length - 1],
            numberOfTransaction,
            transactionValue,
            unregistred,
            registred,
            true
          ) -
            calculate(
              providers[0],
              numberOfTransaction,
              transactionValue,
              unregistred,
              registred,
              true
            )) *
            100
        ) / 100
      );
    }
  }, [providers]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "," || event.key === ".") {
      event.preventDefault();
    }
  };

  return (
    <Dialog open={isOpen} fullWidth disableEscapeKeyDown fullScreen={isMobile}>
      {isProvidersLoading && <Loader />}
      <IconButton
        onClick={(e) => closeModal()}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ p: 4 }}>
        <Typography fontWeight={600} textAlign={"center"} pb={2}>
          Sprawdź ile możesz zaoszczędzić!
        </Typography>
        <Typography textAlign={"center"} pb={3} fontStyle={"italic"}>
          Czy wiesz, że wybierając{" "}
          <span className="gradient-span">dopasowanego</span> operatora
          płatności, możesz znacząco zredukować miesięczne koszty związane z
          obsługą transakcji w Twoim sklepie internetowym?
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontStyle={"italic"}>
            Miesięczna liczba transakcji
          </Typography>
          <ReceiptLongIcon fontSize="large" />
        </Box>
        <Slider
          value={numberOfTransaction}
          min={MIN_NUMBER_OF_TRANSACTION}
          max={MAX_NUMBER_OF_TRANSACTION}
          sx={{ pb: 3 }}
          onChange={(e, newValue) => {
            if (Array.isArray(newValue)) {
              setNumberOfTransaction(newValue[0]);
            } else {
              setNumberOfTransaction(newValue);
            }
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 4 }}>
          <TextField
            value={numberOfTransaction}
            onChange={(e) => setNumberOfTransaction(+e.target.value)}
            label={"Ilość transakcji w miesiącu"}
            type="number"
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontStyle={"italic"}>
            Średnia wartość transakcji
          </Typography>
          <ShoppingCartCheckoutIcon fontSize="large" />
        </Box>

        <Slider
          value={transactionValue}
          min={MIN_VALUE_OF_TRANSACTION}
          max={MAX_VALUE_OF_TRANSACTION}
          sx={{ pb: 3 }}
          onChange={(e, newValue) => {
            if (Array.isArray(newValue)) {
              setTransactionValue(newValue[0]);
            } else {
              setTransactionValue(newValue);
            }
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 4 }}>
          <TextField
            value={transactionValue}
            onChange={(e) => setTransactionValue(+e.target.value)}
            label={"Średnia kwota transakcji"}
            type="number"
            onKeyDown={handleKeyDown}
          />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              pb: { sm: 2 },
            }}
          >
            <Typography textAlign={"start"} fontStyle={"italic"}>
              Twoja oszczędność przy wyborze <b>najkorzystniejszej</b> oferty{" "}
              <span className="gradient-span">
                {providers[0]?.ProviderName}
              </span>
              , względem <b>najmniej korzystnej</b> oferty.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              pb: { xs: 2, sm: 0 },
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center",
            }}
          >
            {/* <Typography
              margin={"auto auto"}
              textAlign={"center"}
              fontWeight={800}
              fontSize={20}
              fontStyle={"italic"}
              color={"rgb(56, 182, 255)"}
            >
              {savings} zł/miesięcznie
            </Typography> */}
            <Typography
              margin={"auto auto"}
              textAlign={"center"}
              fontWeight={800}
              fontSize={20}
              fontStyle={"italic"}
              color={"rgb(56, 182, 255)"}
            >
              {savingsWithVerificationFee} zł/rocznie
            </Typography>
          </Grid>
        </Grid>
        <Grid container pb={2}>
          <Grid item xs={12} sm={6}>
            <Typography textAlign={"start"} fontStyle={"italic"}>
              Twoja oszczędność przy wyborze najkorzystniejszej oferty
              bezpośrednio z{" "}
              <span className="gradient-span">naszej strony</span>.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              pb: { xs: 2, sm: 0 },
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center",
            }}
          >
            <Typography
              margin={"auto auto"}
              textAlign={"center"}
              fontWeight={800}
              fontSize={20}
              fontStyle={"italic"}
              color={"rgb(56, 182, 255)"}
            >
              15 zł/miesięcznie
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          pb={1}
          sx={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center",
          }}
        >
          <Link href={providers[0]?.Link} target="_blank">
            <button className="payment-provider-button">
              Wybieram {providers[0]?.ProviderName} i oszczędzam!
            </button>
          </Link>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SavingsModal;
