import "./Text.css";
import arrow from "./../../images/arrow.png";

type FontType = "bold" | "normal" | "normal-long" | "bold-long";

interface TextProps {
  type: FontType;
  text: string;
}

const Text: React.FC<TextProps> = (props) => {
  return (
    <div className={`custom-${props.type}`}>
      <h3 className={`text-${props.type}`}>
        {props.text}{" "}
        {props.type === "normal-long" ? (
          <img className="background-arrow" src={arrow} alt="Strzałka" />
        ) : (
          <></>
        )}
      </h3>
    </div>
  );
};

export default Text;
