import Box from "@mui/material/Box";
import EmailIcon from "@mui/icons-material/Email";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { scrollToSection } from "../../helpers/refHelper";
interface AboutTabProps {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  contactRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const CooperateTab: React.FC<AboutTabProps> = (props) => {
  return (
    <>
      <HandshakeIcon
        sx={{
          position: "absolute",
          display: { xs: "none", md: "block" },
          fontSize: { xs: 80, md: 150 }, // Responsive size
          color: "rgba(0, 0, 0, 0.1)", // Transparent black
          top: "30%",
          right: { md: "0%", lg: "10%" },
          transform: "translate(-50%, -50%)", // Offset for proper alignment
        }}
      />
      <div className="margin-top-40"></div>
      <div className="custom-subtitle">
        <h2 className="subtitle-centered-text">
          Jeśli reprezentujesz&nbsp;
          <span className="gradient-span">operatora płatności</span>, firmę
          z&nbsp;<span className="gradient-span">branży finansowej</span>
          &nbsp;lub dostawcę usług dla&nbsp;
          <span className="gradient-span">e-commerce</span>, zapraszamy do
          współpracy! Jesteśmy otwarci na partnerstwa, które pozwolą nam
          wspólnie dostarczać najlepsze rozwiązania płatnicze dla
          przedsiębiorców. Skontaktuj się z nami, aby omówić możliwości
          współpracy i wspólne projekty.
        </h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="54"
          height="54"
        >
          <path d="M13 2L10 12H14L11 22L17 12H13L16 2Z" fill="#05a3ff" />
        </svg>
      </div>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "center" }} // Center the button horizontally
        onClick={() => {
          props.setTab("contact");
          scrollToSection(props.contactRef);
        }}
      >
        <Box
          sx={{
            border: "1px solid #05a3ff",
            borderRadius: "16px",
            boxShadow: "0 16px 24px rgba(0, 0, 0, 0.5)",
            padding: "12px 28px", // Reduced padding
            backgroundColor: "#05a3ff",
            opacity: "80%",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0 16px 24px rgba(0, 0, 0, 0.6)",
              border: "1px solid #05a3ff",
            },
            width: "auto", // Adjust width automatically based on content
            maxWidth: "200px", // Limit the maximum width of the button
            margin: "0", // Remove extra margins
          }}
        >
          <div className="custom-subtitle">
            <Box display="flex" justifyContent="center" alignItems="center">
              <EmailIcon
                fontSize="medium"
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
              />
              <h2
                className="subtitle-centered-text"
                style={{
                  color: "#fff",
                  fontSize: 20, // Smaller font size
                  paddingLeft: 8, // Adjust padding to fit the text
                  paddingTop: 3,
                  margin: 0, // Remove extra margin
                }}
              >
                Napisz do nas
              </h2>
            </Box>
          </div>
        </Box>
      </div>
      <div className="margin-top-40"></div>
    </>
  );
};
